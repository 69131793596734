import { ProtectionPackModal } from 'components/Handset/CustomModals/ProtectionPackModal';
import { AOBundle } from 'components/Handset/CustomModals/AOBundle/AOBundle';
import { PromotionModal } from 'components/Handset/PromotionModal';

const PromotionModalMap = new Map([
	['protection-pack', ProtectionPackModal],
	['ao-bundle', AOBundle],
]);

export function usePromo(promo) {
	const hasOverrideModal = (): boolean => {
		return (
			promo.slug.includes('free-protection-pack') ||
			promo.tagLine.includes('Free Protection Pack') ||
			promo.tagLine.includes('Free Protective') ||
			(promo.about && promo.about.includes('AO bundle'))
		);
	};

	const getPromoImage = (): string => {
		if (promo.about && promo.about.includes('AO bundle')) {
			return '/images/bespoke-promos/ao-bundle/AO-Mobile-Phones-Bundle.png';
		}

		return promo.image;
	};

	const getModalForPromo = () => {
		if (
			(promo.slug.includes('free-protection-pack') ||
				promo.tagLine.includes('Free Protection Pack') ||
				promo.tagLine.includes('Free Protective')) &&
			PromotionModalMap.has('protection-pack')
		) {
			return PromotionModalMap.get('protection-pack');
		}

		if (
			promo.about &&
			promo.about.includes('AO bundle') &&
			PromotionModalMap.has('ao-bundle')
		) {
			return PromotionModalMap.get('ao-bundle');
		}

		return PromotionModal;
	};

	return {
		getModalForPromo,
		hasOverrideModal,
		getPromoImage,
	};
}
