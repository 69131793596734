import React from 'react';
import { Column, Row } from '@ao-internal/react-components';
import { Image } from 'components/Common/Image';
import { Accordion } from 'components/Common/Accordion';
import { HandsetPromo } from 'components/types';

export function PromotionModal({
	promo,
}: {
	promo: HandsetPromo;
}): JSX.Element {
	return (
		<>
			<div
				className="promo-background-inner u-c--inverted u-p--medium u-px--giant--bp-medium u-py--large--bp-medium"
				style={{ backgroundColor: '#EDF2FF' }}
			>
				<Row className="u-fxw--wrap-reverse">
					<Column size={12} medium={8}>
						<h2
							className="u-fw--600 u-my--small"
							style={{ color: '#0564C2' }}
						>
							{promo.name}
						</h2>
						<p
							className="u-fz--title-small"
							style={{ color: '#0564C2' }}
						>
							{promo.about}
						</p>
					</Column>
					<Column
						size={12}
						medium={4}
						className="u-ta--center u-my--tiny"
					>
						<Image
							path={`${promo.image}`}
							style={{
								maxWidth: '200px',
								maxHeight: '200px',
							}}
							alt={promo.name}
						/>
					</Column>
				</Row>
			</div>
			<Row className="u-pt--large u-px--xlarge--bp-medium u-px--tiny">
				<Column>
					<h2 className="u-my--small">How to claim</h2>
					<div
						className="promo-how-to-claim u-fz--title-small"
						dangerouslySetInnerHTML={{
							__html: promo.howToClaim,
						}}
					/>
				</Column>
			</Row>
			<Row className="u-px--xlarge--bp-medium u-p--medium">
				<Column>
					<Accordion title="Terms and Conditions">
						<div
							dangerouslySetInnerHTML={{
								__html: promo.terms,
							}}
						></div>
					</Accordion>
				</Column>
			</Row>
		</>
	);
}
