import React from 'react';
import './BundleItem.scss';
import { Image } from 'components/Common/Image';

export function BundleItem({
	name,
	description,
	image,
}: {
	name: string;
	description: string;
	image: string;
}): JSX.Element {
	return (
		<div className="BundleItem">
			<div className="BundleItem--Image">
				<Image path={image} alt="" />
			</div>

			<div className="BundleItem--Content">
				<h3 className="BundleItem--Title">{name}</h3>
				<p>{description}</p>
			</div>
		</div>
	);
}
