import React, { Dispatch, SetStateAction } from 'react';
import './Terms.Three.scss';
import { Button } from '@ao-internal/react-components';

interface TermsThreeProps {
	isOpen?: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function TermsThree({ setIsOpen }: TermsThreeProps) {
	return (
		<div className="Terms--Three">
			<div className="Terms--Three--Header">
				<h1>Three</h1>
				<Button onClick={() => setIsOpen(false)}>
					<i className="ico ico-close" />
				</Button>
			</div>
			<div className="Terms--Three--Content">
				<p>
					Each April, your monthly subscription charge will increase
					depending on how much data you have bought, please see the
					table below:
				</p>
				<table>
					<thead>
						<tr>
							<th>Data allowance range</th>
							<th>Cost increase</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Lower or equal to 4GB</td>
							<td>&pound;1.00</td>
						</tr>
						<tr>
							<td>Greater than 4GB, less than 100GB</td>
							<td>&pound;1.25</td>
						</tr>
						<tr>
							<td>Greater or equal to 100GB</td>
							<td>&pound;1.50</td>
						</tr>
					</tbody>
				</table>
				<div className="Terms--Three--Small">
					Please see network Terms &amp; Conditions for more details -
					you&apos;ll find these on the checkout page.
				</div>
			</div>
		</div>
	);
}
