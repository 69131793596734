import React, { useState } from 'react';
import { Basket, Deal } from 'components/types';
import './Terms.scss';
import { Flags } from 'services/LaunchDarkly/flags';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Button } from 'components/Common/Button';
import { CentredModal } from 'components/Common/CentredModal/CentredModal';
import { TermsThree } from './Terms.Three';

interface TermsProps {
	deal: Deal | Basket;
}

// Cant use context due to basket using different context props
export function Terms({ deal }: TermsProps): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	const [show, setShow] = useState(true);
	const isSimFree =
		deal.type === 'sim-free' || !deal.monthlyBeforeCashbackDisplay;

	launchDarklyClient.getFlag(Flags.DealTerms).then(setShow);

	let network = '';
	if (deal.tariff) network = deal.tariff.network.slug;

	const isMVNO = !['o2', 'three', 'vodafone'].includes(network);

	if (!show || isSimFree || isMVNO) return <></>;

	let text = <></>;
	switch (network) {
		case 'o2': {
			text = (
				<>
					Each April, your monthly subscription charge will increase
					by the Retail Price Index (RPI) rate published in February
					of that year plus 3.9%. If RPI is negative, only the 3.9%
					will be applied.
				</>
			);
			break;
		}
		case 'vodafone': {
			let increaseValueText = '1.80';
			if (deal.tariff.group?.match(/Vodafone Basic Plan/i)) {
				increaseValueText = '1.00';
			}
			text = (
				<>
					Monthly plan increases annually on 1st April by £
					{increaseValueText}.
				</>
			);
			break;
		}
		case 'three': {
			text = (
				<>
					The monthly price will increase each April. Please see the
					table{' '}
					<Button
						variant="link"
						onClick={() => setIsOpen(true)}
						style={{
							fontSize: '.625rem',
							lineHeight: 'inherit',
						}}
					>
						here
					</Button>{' '}
					for more information.
					<CentredModal
						hasPadding={false}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						className="Terms--Three--Container"
					>
						<TermsThree setIsOpen={setIsOpen} />
					</CentredModal>
				</>
			);
			break;
		}
		default: {
			text = (
				<>
					Monthly price will increase each April by the Consumer Price
					Index rate of inflation published in January of that year +
					an additional 3.9%.
				</>
			);
		}
	}

	return <div className="DealPanel--Terms">{text}</div>;
}
