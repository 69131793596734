import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from '@ao-internal/react-components';
import { Deal as DealType } from 'components/types';
import { DealContext, getHasPromo } from '.';
import { Image } from 'components/Common/Image';
import { Accordion } from 'components/Common/Accordion';
import { LinkIcon } from 'components/Common/Icons/LinkIcon';
import { TagIconDouble } from 'components/Common/Icons/TagIconDouble';

import './Promo.scss';
import { ProtectionPackModal } from 'components/Handset/CustomModals/ProtectionPackModal';
import { Deal as DealEnum } from 'enums/Deal.enum';
import { usePromo } from 'hooks/use-promo';

const PromotionModalMap = new Map([['protection-pack', ProtectionPackModal]]);

function PromoModalContent({ promo, deal = null }) {
	if (promo === undefined) {
		promo = deal.variant ? deal.variant.handset.promo : deal.tariff.promo;
	}

	const { getModalForPromo, hasOverrideModal } = usePromo(promo);

	const shouldShowNewProtectionPackModal =
		(promo.tagLine.includes('Free Protection Pack') ||
			promo.tagLine.includes('Free Protective')) &&
		PromotionModalMap.get('protection-pack');

	if (hasOverrideModal()) {
		const Component = getModalForPromo();
		return <Component deal={deal} promo={promo} />;
	}

	return (
		<>
			<div
				className="promo-background-inner u-p--medium u-px--giant--bp-medium u-py--large--bp-medium"
				style={{ backgroundColor: '#EDF2FF' }}
			>
				<Row className="u-fxw--wrap-reverse">
					<Column size={12} medium={8}>
						<h2
							className="u-fw--600 u-my--small"
							style={{ color: '#0564C2' }}
						>
							{promo.name}
						</h2>
						<p
							className="u-fz--title-small"
							style={{ color: '#0564C2' }}
						>
							{promo.about}
						</p>
					</Column>
					<Column
						size={12}
						medium={4}
						className="u-ta--center u-my--tiny"
					>
						<Image
							path={`${promo.image}`}
							style={{ maxWidth: '200px', maxHeight: '200px' }}
							alt={promo.name}
							aria-hidden={true}
						/>
					</Column>
				</Row>
			</div>
			<Row className="u-pt--large u-px--xlarge--bp-medium u-px--tiny">
				<Column>
					<h2 className="u-my--small">How to claim</h2>
					<div
						className="promo-how-to-claim u-fz--title-small"
						dangerouslySetInnerHTML={{ __html: promo.howToClaim }}
					/>
				</Column>
			</Row>
			<Row className="u-px--xlarge--bp-medium u-p--medium">
				<Column>
					<Accordion title="Terms and Conditions">
						<div
							dangerouslySetInnerHTML={{ __html: promo.terms }}
						></div>
					</Accordion>
				</Column>
			</Row>
		</>
	);
}

function Promo({ openModalWithDeal }) {
	const { deal } = useContext<{ deal: DealType }>(DealContext);

	const promo = getHasPromo(deal);

	const { showSimFree, showNewContract, showUpgrade } = promo;

	if (
		showSimFree &&
		!showNewContract &&
		!showUpgrade &&
		deal.type !== DealEnum.SimFree
	) {
		return <></>;
	}

	if (showNewContract && !showUpgrade && deal.type !== DealEnum.NewContract) {
		return <></>;
	}

	if (showUpgrade && !showNewContract && deal.type !== DealEnum.Upgrade) {
		return <></>;
	}

	if (
		(showUpgrade || showNewContract) &&
		!showSimFree &&
		deal.type === DealEnum.SimFree
	) {
		return <></>;
	}

	const buttonText = `Offer: ${promo.tagLine}`;

	/*const isRefurb =
    deal.variant &&
    (deal.variant.conditionId === 2 || deal.variant.handset.conditionId === 2);

  if (isRefurb) {
    return null;
  }*/
	function trackPromoPillClicked() {
		globalThis.dataLayer.push({
			event: 'Promo Panel Clicked',
			eventCategory: 'Promo Panel Clicked',
			eventAction: window.MPD.Analytics.pageType,
			eventLabel: promo.name,
		});
	}

	return (
		<Column className="u-px--micro u-py--micro">
			<button
				className="c-btn--deal-feature-button u-d--flex u-font-group--display u-fw--600 u-p--tiny u-w--100 u-ta--left"
				style={{ backgroundColor: '#EDF2FF' }}
				onClick={() => {
					openModalWithDeal({
						deal,
						promo,
						Renderer: PromoModalContent,
						title: 'Promotion',
					});
					trackPromoPillClicked();
				}}
				aria-label={buttonText}
			>
				<span
					className="u-my--auto u-d--flex"
					style={{
						lineHeight: '1.3',
						color: '#0564C2',
						paddingRight: '5px',
					}}
				>
					<span
						className="u-my--auto"
						style={{ marginLeft: '5px', marginRight: '10px' }}
					>
						<TagIconDouble colour="#0564C2" width={25} />
					</span>
					{promo.tagLine}
				</span>

				<LinkIcon
					colour="#0564C2"
					className="u-fxsh--0 u-ml--auto u-my--auto u-mr--micro"
				/>
			</button>
		</Column>
	);
}
Promo.propTypes = {
	openModalWithDeal: PropTypes.func.isRequired,
};

export { Promo, PromoModalContent };
