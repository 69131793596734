import React from 'react';
import { Deal, HandsetPromo } from 'components/types';
import { Column, Row } from '@ao-internal/react-components';
import { Image } from 'components/Common/Image';
import { Accordion } from 'components/Common/Accordion';
import { BundleItem } from 'components/Handset/CustomModals/AOBundle/BundleItem';

interface BundleItem {
	name: string;
	image: string;
	description: string;
}

const BUNDLE_ITEMS: Array<BundleItem> = [
	{
		name: 'AO 45w Charger and 2 x USB-C Cable - Fixed Pin',
		image: '/images/bespoke-promos/ao-bundle/AO-Mobile-Charger.png',
		description:
			'This Super-Fast Charging travel adapter gets your phone topped up quickly wherever you are. Just pop it in a socket and it’ll provide 45W of power straight from the mains to your mobile. It comes with 2 x USB- C 1m length tangle-free cables.',
	},
	{
		name: 'Single Screen Protector',
		image:
			'/images/bespoke-promos/free-protection-pack/protection-bundle.svg',
		description:
			'You’ve treated yourself to a super slick, new phone, now give it the TLC it deserves with this screen protector for iPhone 16. Super easy to apply, it keeps the front glass safe from scratches – that means those keys in your pocket won’t spoil the party. And because it’s fingerprint-resistant too, you can swipe through the shops without leaving pesky smudges. If it does need a quick wipe down though, there’s a convenient cleaning cloth in the box too.',
	},
];

export function AOBundle({
	promo,
	deal = null,
}: {
	promo: HandsetPromo;
	deal?: Deal;
}): JSX.Element {
	return (
		<>
			<div
				className="promo-background-inner ProtectionPackModal u-c--inverted u-p--medium u-px--giant--bp-medium u-py--large--bp-medium"
				style={{ backgroundColor: '#EDF2FF' }}
			>
				<Row className="u-fxw--wrap-reverse">
					<Column size={12} medium={9}>
						<h2
							className="u-fw--600 u-my--small"
							style={{ color: '#0564C2' }}
						>
							{promo.name}
						</h2>
						<p
							className="u-fz--title-small"
							style={{ color: '#0564C2' }}
						>
							Get a free AO Bundle worth £60 when you buy a new
							contract or upgrade on this handset. The super-fast
							charger has been paired with a high-quality camera
							and screen protector. Crafted with precision and
							designed for durability, this duo offers the
							essentials you need to protect and maintain your
							latest smartphone. The two products will be shipped
							with the handset.
						</p>
					</Column>
					<Column
						size={12}
						medium={3}
						className="u-ta--center u-my--tiny"
					>
						<Image
							path="/images/bespoke-promos/ao-bundle/AO-Mobile-Phones-Bundle-2.png"
							style={{
								maxWidth: '200px',
								maxHeight: '200px',
							}}
							alt={promo.name}
						/>
					</Column>
				</Row>

				{BUNDLE_ITEMS.length > 0 && (
					<>
						<Row>
							<h2
								className="u-fz--title-small ProtectionPackModal--Includes"
								style={{ color: '#0564C2' }}
							>
								AO Bundle Includes:
							</h2>
						</Row>
						<Row>
							<div className="includes">
								{BUNDLE_ITEMS.map(item => {
									return (
										<BundleItem key={item.name} {...item} />
									);
								})}
							</div>
						</Row>
					</>
				)}
			</div>
			<Row className="u-pt--large u-px--xlarge--bp-medium u-px--tiny">
				<Column>
					<h2 className="u-my--small">How to claim</h2>
					<div
						className="promo-how-to-claim u-fz--title-small"
						dangerouslySetInnerHTML={{
							__html: promo.howToClaim,
						}}
					/>
				</Column>
			</Row>
			<Row className="u-px--xlarge--bp-medium u-p--medium">
				<Column>
					<Accordion title="Terms and Conditions">
						<div
							dangerouslySetInnerHTML={{
								__html: promo.terms,
							}}
						></div>
					</Accordion>
				</Column>
			</Row>
		</>
	);
}
